import { CentreContainer } from "@/components/Containers";
import { AuthOptions } from "@/pages/api/auth/[...nextauth].page";
import { setIdToken } from "@/store/authSlice";
import { unixTimestampToDate } from "@/utils/core/date.helpers";
import { DecodedIdToken, SessionResult } from "@/types";
import { captureException } from "@sentry/nextjs";
import { Button, Card, Spin } from "antd";
import { isFuture } from "date-fns";
import jwt_decode from "jwt-decode";
import { GetServerSideProps, NextPage } from "next";
import { getServerSession } from "next-auth";
import { signIn, signOut } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import parse from "url-parse";

const SignIn: NextPage<SignInProps> = ({ authorizationParams }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const handleSignIn = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await signIn(
        "auth0",
        { callbackUrl: "/" },
        authorizationParams ? { ...authorizationParams } : undefined,
      );

      if (result?.error) {
        // if unable to sign in, make sure we fully sign out to allow user to try again
        captureException(result.error);
        dispatch(setIdToken(null));
        signOut({ callbackUrl: "/api/auth/logout" });
      }
    } catch (e) {
      captureException(e);
      setLoading(false);
    }
  }, [dispatch, authorizationParams]);

  useEffect(() => {
    // by default we want to go straight to the Auth0 sign-in page when this page loads. We have the Sign-in button as a fallback if something goes wrong which allows the user to manually trigger the sign-in flow.
    handleSignIn();
  }, [handleSignIn]);

  if (loading) {
    return (
      <CentreContainer>
        <Spin size="large" />
      </CentreContainer>
    );
  }

  return (
    <CentreContainer>
      <Card style={{ padding: 50 }}>
        <Button type="primary" onClick={handleSignIn}>
          Sign In to PROPeL
        </Button>
      </Card>
    </CentreContainer>
  );
};

export default SignIn;

interface SignInProps {
  authorizationParams: Record<string, string> | undefined;
}

export const getServerSideProps: GetServerSideProps = async context => {
  if (context.query && typeof context.query.callbackUrl === "string") {
    /**
     * Auth0 invite links will be passed through as a callbackUrl
     * with a format such as: '/login?invitation=blah&organization=blah&organization_name=mk-dev'
     * We want to be able to pass these additional parameters through to the Auth0 sign in request
     */
    const parsedUrl = parse(context.query.callbackUrl, true);
    const query = parsedUrl.query;
    if (query) {
      return { props: { authorizationParams: query } };
    }
  }

  const session = (await getServerSession(context.req, context.res, AuthOptions)) as SessionResult;
  if (session?.idToken) {
    const decodedIdToken = jwt_decode<DecodedIdToken>(session.idToken);
    const tokenIsNotExpired = isFuture(unixTimestampToDate(decodedIdToken.exp));
    if (tokenIsNotExpired) {
      // if the user is already authenticated and they try to access the sign-in page, push them through to the dashboard
      return {
        redirect: {
          permanent: false,
          destination: "/",
        },
      };
    }
  }

  return {
    props: {},
  };
};
