import styled from "@emotion/styled";

export const CentreContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

type FlexProps = {
  /**
   * The gap between the children, in px
   */
  gap?: number;

  /**
   * The flex value
   */
  flex?: number;
};

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
  ${({ flex }) => (flex ? `flex: ${flex};` : "")}
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const RowCenter = styled(Row)`
  align-items: center;
`;

export const Col = styled(Flex)`
  flex-direction: column;
`;

export const ColCenter = styled(Col)`
  align-items: center;
`;

export const RowBetween = styled(RowCenter)`
  justify-content: space-between;
`;
